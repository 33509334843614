// DO NOT USE THOSE VARIABLES ANYWHERE ELSE IN THE APP
// They're specifically only for the navbar

//** grays
$black:                  #000 !default;
$nav-white:              #fff !default;
$gray-dark:              lighten($black, 20%) !default;   // #333
$gray:                   lighten($black, 33.5%) !default; // #555
$gray-light:             #878787 !default;   // #888
$creme-gray:             #daddd6 !default;
$light-background:       #f0f0f0 !default;

//** global colors
$global-blue:           #309ddc !default;

//** default brand colors
$brand-primary:         #309ddc !default;
$brand-secondary:       #65c5ff !default;
$brand-deep-text:       #465163 !default;

// Product colors -color to be used when the background color is light
$g2m-color:                     #e18026 !default;
$g2t-color:                     #992977 !default;
$g2w-color:                     #3797d1 !default;
$g2a-color:                     #2a969d !default;
$openvoice-color:               #5c61a6 !default;

// Product colors -color-light to be used when the background color is dark
$g2m-color-light:               #fd7a2b !default;
$g2t-color-light:               #ff4a90 !default;
$g2w-color-light:               #42c2f6 !default;
$g2a-color-light:               #39c8d2 !default;
$openvoice-color-light:         #b380ff !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-family-strong:      lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-light:  lato-light, "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-semibold:  lato-semibold, "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$font-size-base:          14px !default;
$font-size-small:         ceil(($font-size-base * .85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//== Components
$border-radius-base:        2px !default;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    $nav-white !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary !default;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    $nav-white !default;
//** Dropdown menu `border-color`.
$dropdown-border:                #ccc !default;
//** Divider color for between dropdown items.
$dropdown-divider-bg:            #aaa !default;

//** Dropdown link text color.
$dropdown-link-color:            $brand-deep-text !default;
//** Hover color for dropdown links.
$dropdown-link-hover-color:      $global-blue !default;
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         #F5F5F5 !default;
///** selected dropdown font weight
$dropdown-selected-font-weight: 900;

//** Active dropdown menu item text color.
$dropdown-link-active-color:     $component-active-color !default;
//** Active dropdown menu item background color.
$dropdown-link-active-bg:        $component-active-bg !default;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-light !default;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-light !default;

$dropdown-letter-spacing:        0;
$dropdown-min-width:             160px;

$nav-dropdown-width:             242px;
$nav-dropdown-max-width:         $nav-dropdown-width;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
// $screen-phone:               $screen-xs-min !default;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;

// Large screen / wide desktop
$screen-lg:                  1200px !default;

//== Grid system
//
//## Define your custom responsive grid.
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

//== Navbar
//
//##

// Basics of a navbar
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
$navbar-default-color:             #9096a1 !default;
$navbar-default-bg:                #485162 !default;

// Navbar links
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-disabled-color:       #444 !default;
$navbar-default-link-disabled-bg:          transparent !default;

// Navbar brand label
$navbar-default-brand-hover-bg:            transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg:           transparent !default;
$navbar-default-toggle-icon-bar-bg:        $nav-white !default;
$navbar-default-toggle-border-color:       transparent !default;

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$lite-grid-float-breakpoint:                    $screen-sm-min !default;

$lite-grid-float-breakpoint-max:                ($lite-grid-float-breakpoint - 1) !default;

// Basics of a navbar
$lite-navbar-height:                            64px !default;
$lite-navbar-padding-vertical:                  ((($lite-navbar-height + 1) - $line-height-computed) / 2) !default;

$lite-navbar-default-bg:                        $nav-white !default;

$lite-navbar-default-border:                    darken($lite-navbar-default-bg, 10%) !default;

// Navbar links
$lite-navbar-default-link-color:                #8997ad !default;
$lite-navbar-default-link-hover-color:          #2c72de !default;
$lite-navbar-default-link-active-color:         #444 !default;
$lite-navbar-default-link-active-bg:            $lite-navbar-default-bg !default;
$lite-navbar-default-link-icon-color:           #b5b4b5 !default;

// Navbar brand label
$lite-navbar-default-brand-color:               #89898a !default;
$lite-navbar-default-brand-hover-color:         $lite-navbar-default-brand-color !default;
$lite-navbar-default-brand-full-height:         38px;

///** separtor color - hr and li
$lite-nav-menu-separator-color:                 #f0f0f0 !default;
