@import 'variables';

// general
.no-margin {
  margin: 0;
}

// Override Chameleon using webinar colors
a {
  color: $dodger-blue;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $denim-blue;
  }
}

// Override bootstrap checkbox which is too gray in unchecked state
.custom-control-label::before {
  background-color: transparent !important;
  border: 1px solid $satin-doll !important;
}

// Override bootstrap disabled form input

.form-control:disabled {
  background-color: $white;
  color: $metallic-cloud;
  cursor: not-allowed;
}

hr {
  border-color: $satin-doll;
}

ul.no-bullets {
  list-style: none;
}

.green-text {
  color: $jade-green;
}

.vcenter-container {
  display: table;
  width: 100%;
  height: 100%;
}

.vcenter-container-ellipsis {
  @extend .vcenter-container;
  table-layout: fixed;
}

.vcenter-content {
  display: table-cell;
  vertical-align: middle;
}

hr {
  margin: 0;
}

.app-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
}

// Adjust the app content padding bottom since our footer is larger than our default togo-footer due to the OV legal text
.app-content {
  margin-top: 45px;
  padding-bottom: 120px;

  @media (max-width: $screen-xs-max) {
    padding-bottom: 175px;
  }
}

// Using label-value-vertical-intl-view for contact sales component in navbar
.nav li.contact-sales {
  color: $white;
  height: 50px;
  margin-right: 10px;

  .value-label {
    color: $satin-doll;
    font-size: .8em;
  }

  .value {
    font-size: 1em;
    color: $white;
  }
}

.loading-overlay {
  position: absolute;
  background-color: $white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  & > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

// @FIXME these Bootstrap modal overrides should be in Chameleon
.modal-header {
  padding: 0 30px 15px;
  border-bottom: 1px solid $white;
  letter-spacing: .5px;
  font-size: 1.5em;
  background-color: $white;
  justify-content: center;
}

.modal-footer {
  margin-top: 0;
  margin-bottom: 25px;
  border-top: 1px solid $white;
}

.modal-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & button, & a{
    margin: 5px;
  }

  @media (min-width: $screen-xs-max) {
    flex-direction: row;
    justify-content: center;

    &.stacked {
      flex-direction: column;
    }
  }
}

.form-text-error {
  color: $radical-red;
  font-size: .75em;
  position: absolute;
  top: 88%;
}

.form-optional {
  margin-left: 5px;
  color: $silver-soul;
}

// subscription configuration section
.tile {
  cursor: pointer;
  position: relative;
  background-color: transparent;
  padding: 12px 0 0 0;
  border: $silver-soul solid 1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &:hover {
    border: $dodger-blue solid 1px;
  }

  button {
    border-radius: 0;
    position: absolute;
    bottom: 0;
    background-color: $dodger-blue;
    color: $white;
  }

  &.selected {
    border: $jade-green solid 1px;

    &:hover {
      border: $laurel-green solid 1px;
    }

    button {
      background-image: none;
      background-color: $jade-green;
      border-color: $jade-green;
      color: $white;

      &:hover, &:hover:enabled, &:focus, &:focus:enabled {
        background-image: none;
        background-color: $laurel-green;
        border-color: $laurel-green;
        box-shadow: none;
      }

      &:not(:disabled):not(.disabled):active {
        background-image: none;
        background-color: $jade-green;
      }

      &:disabled.btn-default {
        background-color: $silver-soul;
        border-color: inherit;
      }
    }
  }
}

.feature-disabled {
  color: $silver-soul;

  span i {
    color: $silver-soul;
  }

  div i {
    color: $silver-soul;
  }
}

// This class is an override of Bootstraps tooltip class. It should be removed once we update ToGo. BPOR-835
.tooltip {
  .tooltip-inner {
    color: $grey-street;
    background-color: $white;
    opacity: 1;
    border: 1px solid $satin-doll;
    -webkit-box-shadow: 3px 3px 4px 0 rgba($dark-fantasy, .32);
    box-shadow: 3px 3px 4px 0 rgba($dark-fantasy, .32);
  }

  &.top .tooltip-arrow {
    border-top-color: rgba($dark-fantasy, .12);
    &:after {
      border-top-color: $white;
    }
  }
  &.right .tooltip-arrow {
    border-right-color: rgba($dark-fantasy, .12);
    &:after {
      border-right-color: $white;
    }
  }
  &.left .tooltip-arrow {
    border-left-color: rgba($dark-fantasy, .12);
    &:after {
      border-left-color: $white;
    }
  }
  &.bottom .tooltip-arrow {
    border-bottom-color: $satin-doll;
    &:after {
      border-bottom-color: $white;
    }
  }
}

// checkout and cart section
.credit {
  color: $jade-green !important;
}

.discount-text {
  color: $jade-green !important;
}

.changed-price {
  color: $grey-street !important;
  text-decoration: line-through;
}

// mobile friendly section
@media (max-width: 900px) {
  .mobile-font-md {
    font-size: 16px;
  }
}

@media (max-width: $screen-xs-max) {
  .text-center-xs {
    text-align: center;
  }
}

@media (max-width: $screen-sm-max) {
  .mobile-font-md {
    font-size: 16px;
  }
}

// Chameleon Button:
.btn {
  height: 44px;
  letter-spacing: 1px;
  line-height: 1.25;

  &.btn-primary {
    background: $black;
    border: none;
    &:hover:enabled {
      background-image: $button-hover-overlay-gradient, $button-linear-gradient;
    }
    &:not(:disabled):not(.disabled):active {
      background-image: $button-linear-gradient;
      &:focus {
        box-shadow: none;
      }
    }
    &:focus {
      box-shadow: $button-focus-box-shadow;
    }
  }
  &.btn-secondary {
    color: $cyan-blue-dk;
    border-style: solid;
    border-width: 3px;
    border-color: $gunmetal-gray-trans;
    background: $white;
    background-image: $white, $button-linear-gradient;
    &:hover:enabled {
      color: $cyan-blue-dk;
      background-color: $button-hover-bg;
      border-color: $button-hover-border;
    }
    &:not(:disabled):not(.disabled):active {
      color: $cyan-blue-dk;
      background-color: $button-active-bg;
      border-color: $gunmetal-gray-trans;
      &:focus {
        box-shadow: none;
        background-color: $white;
        background-image: none;
      }
    }
    &:focus {
      box-shadow: $button-focus-box-shadow;
    }
  }
  &.btn-tertiary {
    color: $dodger-blue;
    &:hover, &:focus {
      box-shadow: none;
      color: $denim-blue
    }
  }
  &.btn-link {
    background: none;
    border: none;
    padding: 0;
    color: $dodger-blue;
    letter-spacing: normal;
    text-transform: none;

    &:hover,
    &:focus {
      color: $denim-blue;
    }
  }
}

// label
.label {
  position: relative;
  top: 11px;
  left: 18px;
  background-color: $white;
  color: $grey-street;
  font-size: $font-sm;
  border-radius: 2px;
  opacity: 1;
  z-index: 1;
  transition: opacity .15s ease-in-out;
  &.label-hide {
    opacity: 0;
  }
  &.label-disabled {
    background-color: $white;
    color: $metallic-cloud;
  }
}

// Popover
.bs-popover-right .arrow {
  left: -8px
}

.bs-popover-bottom .arrow {
  top: -8px;
}

.bs-popover-left .arrow {
  right: -8px;
}

.popover-body {
  max-width: 276px;
}

.popover {
  opacity: 0;
  transition: .15s opacity ease-out;

  &.show {
    opacity: 1;
  }
}

// Tooltip
.tooltip-inner {
  max-width: 140px;
}

// Accessibility
a:focus {
  outline: 2px $metallic-cloud solid;
}

// Alert Banner Overwrite
.alert-success:before {
  background: $java-green;
}

// Prevents text highlighting on dropdown items after expansion
.dropdown-item {
  user-select: none;
}


.chameleon-loader-dots-blue {
  // Override Chameleon loader dots blue color
  background-image: url('data:image/svg+xml;utf8,<svg width="75px" height="82px" viewBox="0 0 41 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Loader" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g fill="%23479FFE"><circle class="dots dot1" cx="3.5" cy="13.5" r="3.5"></circle><circle class="dots dot2" cx="21.5" cy="3.5" r="3.5"></circle><circle class="dots dot3" cx="21.5" cy="41.5" r="3.5"></circle><circle class="dots dot4" cx="37.5" cy="13.5" r="3.5"></circle><circle class="dots dot5" cx="3.5" cy="33.5" r="3.5"></circle><circle class="dots dot6" cx="37.5" cy="33.5" r="3.5"></circle></g></g></svg>');
  // Actually ease in and out of the animation
  animation: spin 1.7s cubic-bezier(.65,.05,.36,1) infinite !important;
}

// Needed for initial loader
html, body {
  height: 100%
}
