@import 'navbar_variables';

.navbar-lite {
  background-color: $lite-navbar-default-bg;
  border-color:     $lite-navbar-default-border;
  margin-bottom:    20px;
  position:         relative;
  z-index:          1;
  box-shadow:       0 0 3px 0 rgba(0,0,0,.12), 0 5px 15px 0 rgba(0,0,0,.05);
  min-height:       $lite-navbar-height;
  padding: 0;

  // Navbar heading

  .navbar-header {
    display: flex;
    width: 100%;
    min-height: 64px;
    @include clearfix();

    @media (min-width: $lite-grid-float-breakpoint) {
      float: left;
      width: auto;
    }
  }

  // Navbar collapse (body)

  .navbar-collapse {
    overflow-x: hidden;
    padding-right: $navbar-padding-horizontal;
    padding-left:  $navbar-padding-horizontal;
    border-top: 1px solid transparent;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    background: rgb(255, 255, 255);
    width: 100%;
    z-index: 1000;
    @include clearfix();
    -webkit-overflow-scrolling: touch;

    &.show {
      overflow-y: auto;
    }

    @media (min-width: $lite-grid-float-breakpoint) {
      width: auto;
      border-top: 0;
      box-shadow: none;

      &.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0; // Override default setting
        overflow: visible !important;
      }

      &.show {
        overflow-y: visible;
      }

      position: static;
      background: rgb(255, 255, 255);
      z-index: auto;
      height: auto;
    }
  }


  // Both navbar header and collapse
  //
  // When a container is present, change the behavior of the header and collapse.

  .container,
  .container-fluid {
    > .navbar-header,
    > .navbar-collapse {
      @media (min-width: $lite-grid-float-breakpoint) {
        margin-right:  70px;
        padding-right: 0;
      }
    }
  }

  // Move the navbar dropdown all the way to the right if it's in a fluid container
  .navbar-lite .container-fluid .navbar-right .dropdown:last-child {
    margin-right: -$navbar-padding-horizontal;
  }

  // In collapsed mode do not add extra padding on top of the first nav item (li)
  .navbar-lite .container-fluid .navbar-right .dropdown .nav-dropdown-menu li:first-child {
    margin-top: 0;
  }

  // Navbar alignment options
  .nav-mobile-separator-line {
    margin: 2px 0;
    background-color: $lite-nav-menu-separator-color;
    @media (min-width: $lite-grid-float-breakpoint) {
      display: none;
    }
  }

  // Brand/project name

  .navbar-brand-lite {
    float: left;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.1;
    height: ($lite-navbar-default-brand-full-height - 10px);
    letter-spacing: 0.3px;
    padding-top: 9px;
    padding-left: 32px;
    background-image: url('~static/images/GoTo_RGB_Black_Yellow.png');
    background-repeat: no-repeat;
    background-size: 87px 28px;
    background-position: -62px 0;
    margin-top: 18px;
    margin-left: 20px;

    // Vertically center in expanded, horizontal navbar

    @media (min-width: $screen-xs-min) {
      font-size: 11px;
      font-weight: 600;
      height: $lite-navbar-default-brand-full-height;
      letter-spacing: 1px;
      min-width: 78px;
      margin-right: 13px;
      background-size: 77px 38px;
      background-position: 0 0;
    }


    @media (min-width: $lite-grid-float-breakpoint) {
      margin-left: 10px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    @media (min-width: $lite-grid-float-breakpoint) {
      .navbar > .container &,
      .navbar > .container-fluid & {

        margin-left: $navbar-padding-horizontal !important
      }
    }
  }

  .brand-name{
    margin-top: 16px;
    font-size: 1.25rem;
    font-weight: 735;
  }
  // Navbar toggle
  //
  // Custom button for toggling the `.navbar-collapse`, powered by the collapse
  // JavaScript plugin.

  .navbar-toggler {
    position: relative;
    float: right;
    margin-right: $navbar-padding-horizontal;
    padding: 9px 10px;
    background-color: transparent;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    border-radius: $border-radius-base;
    margin-top: 8px;
    margin-bottom: 8px;

    .togo-icon-hamburger {
      font-size: 30px;
      vertical-align: middle;
    }

    @media (max-width: $lite-grid-float-breakpoint) {
      padding: 7px 10px;
    }

    @media (min-width: $lite-grid-float-breakpoint) {
      display: none;
    }
  }

  // Navbar nav links
  //
  // Builds on top of the `.nav` components with its own modifier class to make
  // the nav the full height of the horizontal nav (above 768px).

  .navbar-nav {
    margin-top:      0px;
    margin-bottom:   2px;
    margin-left:     (-$navbar-padding-horizontal);
    margin-right:    (-$navbar-padding-horizontal);

    &.navbar-right {
      margin-top:      2px;
      margin-bottom:  12px;
      > li {
        margin-left: 0;
      }
      > li > a, > li > span {
        padding-left: 24px;
      }
    }

    > li {
      margin-top:  10px;
      margin-bottom: 10px;
    }

    > li > a, > li > span {
      display:         inline-block;
      padding-top:      4px;
      padding-bottom:   4px;
      padding-left:    22px;
      font-size:       16px;
      line-height:     $line-height-computed;

      &.dropdown-toggle-avatar,
      &.dropdown-toggle-icon {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      &.dropdown-toggle-avatar {
        > img {
          margin-top: 3px;
          display: inline-block;
          position: relative;
          @media (min-width: $lite-grid-float-breakpoint) {
            margin-top: 0px;
          }
        }
        > div {
          display: inline-block;
          position: relative;
          padding-left: 12px;
          font-size: 14px;
          line-height: normal;
          max-width: 300px;
          color: $lite-navbar-default-link-color;
          &:hover,
          &:focus {
            color: $lite-navbar-default-link-hover-color;
          }
          @media (min-width: $lite-grid-float-breakpoint) {
            display: none;
          }
        }

        .user-name {
          font-size: 16px;
          font-family: $font-family-semibold;
          line-height: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .user-email {
          font-family: $font-family-light;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media (min-width: $lite-grid-float-breakpoint) {
          height: 31px;
        }
      }

      .icon-label-lite {
        position: relative;
        display: inline-block;
        padding-left: 6px;
        padding-top: 3px;
        vertical-align: top;
        color: $lite-navbar-default-link-color;
        &:hover,
        &:focus {
          color: $lite-navbar-default-link-hover-color;
        }
      }
    }

    > li > a > img, > li > span > img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
      border-radius: 50%;
      border: 2px solid $lite-navbar-default-bg;
      &:hover,
      &:focus {
        border-color: $lite-navbar-default-link-hover-color;
      }
    }

    > li > a > i, > li > span > i {
      width: 30px;
      font-size: 28px;
      color: $lite-navbar-default-link-active-color;
      &:hover,
      &:focus {
        color: $lite-navbar-default-link-hover-color;
      }
    }

    .togo-icon-cart {
      font-size: 28px;
      line-height: .75em;
      vertical-align: -15%;
      color: $lite-navbar-default-link-active-color;

      &:hover,
      &:focus {
        color: $lite-navbar-default-link-hover-color;
      }

      @media(max-width: $grid-float-breakpoint-max) {
        line-height: 32px;
      }
    }

    .dropdown-menu-user-info {
      padding-top: 10px;
      padding-bottom: 6px;

      > img {
        display: inline-block;
        position: relative;
        width: 40px;
        vertical-align: middle;
        border-radius: 50%;
        border: 2px solid #fff;
      }
      > div {
        display: inline-block;
        position: relative;
        padding-left: 12px;
        font-size: 14px;
        line-height: normal;
        max-width: 200px;
      }

      .user-name {
        font-family: $font-family-semibold;
        color: $lite-navbar-default-link-active-color;
        padding-top: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .user-email {
        font-family: $font-family-light;
        color: $lite-navbar-default-brand-color;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (max-width: $lite-grid-float-breakpoint-max) {
        display: none;
      }
    }

    @media (max-width: $lite-grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .show .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 8px;
        background-color: $nav-white;
        border-top: 1px solid ($lite-navbar-default-link-icon-color) !important;
        border-bottom: 1px solid ($lite-navbar-default-link-icon-color) !important;
        box-shadow: none;
        > li:first-child {
          margin-top: 10px !important;
        }
        > li > a, > li > span,
        .dropdown-header {
          padding: 5px 15px 5px 25px;
        }
        > li > a, > li > span {
          line-height: $line-height-computed;
          width:     100% !important;
          max-width: 100% !important;
          &:hover,
          &:focus {
            background-image: none;
          }
        }
      }

      .show .dropdown-first-menu-item {
        margin-top: 10px !important;
      }

      .dropdown-user-info {
        display: none;
      }

    }

    // Uncollapse the nav
    @media (min-width: $lite-grid-float-breakpoint) {
      float: left;
      margin: 0 80px 0 0;

      &.navbar-right {
        margin-top:    0;
        margin-bottom: 0;

        > li > a, > li > span {
          padding-left: (-$navbar-padding-horizontal);
        }
      }

      > li {
        > a, > span {
          padding-top:    $lite-navbar-padding-vertical;
          padding-bottom: $lite-navbar-padding-vertical;
          margin-right:   24px;
          margin-top:      0;
          margin-bottom:   0;
          padding-left:    0;

          &.dropdown-toggle-avatar {
            margin-right: 0;
          }

          &.dropdown-toggle-avatar,
          &.dropdown-toggle-icon {
            padding-top:     0;
            padding-bottom:  0;
            padding-left:    0;
            @include navbar-vertical-align-lite(30px);
          }

          &.dropdown-toggle-icon {
            padding-right:   0;
            padding-top:     2px;
            @include navbar-vertical-align-lite(31px);
          }

          .icon-label-lite {
            display: none;
          }

        }
      }
      &.navbar-right:last-child {
        margin-right: -$navbar-padding-horizontal;
      }
      &.navbar-right .dropdown:last-child a{
        padding-right: 18px;
      }
    }
  }

  // Component alignment
  //
  // Repurpose the pull utilities as their own navbar utilities to avoid specificity
  // issues with parents and chaining. Only do this when the navbar is uncollapsed
  // though so that navbar contents properly stack and align in mobile.

  @media (min-width: $lite-grid-float-breakpoint) {
    .navbar-left {
      float: left !important;
    }
    .navbar-right {
      position: absolute;
      right: 20px;
    }
  }

  .navbar-nav > li > .dropdown-menu {
    margin-top: 0px;
    margin-right: 0px;
    @include border-top-radius(0);
  }
  // Menu position and menu caret support for dropups via extra dropup class
  .navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
    @include border-bottom-radius(0);
  }

  .navbar-brand-lite {
    color: $lite-navbar-default-brand-color;
    &:hover,
    &:focus {
      color: $lite-navbar-default-brand-hover-color;
      background-color: $navbar-default-brand-hover-bg;
    }
    @media (max-width: 1250px) and (min-width: $screen-xs-max) {
      margin-right: 35px;
    }
  }

  .navbar-nav {
    top: 0;

    > li {
      margin: 24px 0 24px 38px;
    }

    &.navbar-right > li {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      margin-top:    16px;
      margin-bottom: 16px;
    }

    > li > a, > li > span {
      color: $lite-navbar-default-link-color;
      cursor: pointer;
      font-family: $font-family-semibold;
      font-size: 16px;
      line-height: 20px;

      &.dropdown-toggler,
      &:hover,
      &:focus {
        color: $lite-navbar-default-link-hover-color;
        background-color: $navbar-default-link-hover-bg;
      }

    }
    > li > a.active, > li > span.active {
      &,
      &:hover,
      &:focus {
        color: $lite-navbar-default-link-active-color;
        background-color: $lite-navbar-default-link-active-bg;
        background: linear-gradient(266deg, #479ffe, #2c72de)
        0
        100%
        #FFF
        no-repeat;
        background-size: 3px 100%;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
        background-color: $navbar-default-link-disabled-bg;
      }
    }
    @media (min-width: $lite-grid-float-breakpoint) {
      > li {
        margin: 0;
      }

      > li > a, > li > span {
        padding-left: 15px;
      }
      > li > a.active, > li > span.active {
        &,
        &:hover,
        &:focus {
          background-size: 100% 4px;
        }
      }

      &.navbar-right > li {
        margin-top:     0;
        margin-bottom:  0;
      }
    }

    > li > a, > li > span {
      padding: 15px;
      margin-right: 9px;
    }

    @media (min-width: $screen-lg) {
      > li > a, > li > span {
        padding: 21px 15px;
      }
    }

    @media (max-width: 1250px) and (min-width: $screen-xs-max) {
      > li > a, > li > span {
        font-size: 14px;
        padding-top: 21px;
        padding-right: 12px;
        padding-left: 12px;
        margin-right: 9px;
      }
    }
    @media (max-width: 850px) and (min-width: $screen-xs-max) {
      > li > a, > li > span {
        font-size: 13px;
        padding-right: 9px;
        padding-left: 9px;
        margin-right: 9px;
      }
    }
  }

  .navbar-nav:not(.navbar-right) {
    min-height: 64px;

    > li {
      margin: 10px 20px;

      @media (min-width: $screen-sm) {
        margin: 0px;

        > a, > span {
          height: 100%;
        }
      }
    }
  }

  .navbar-toggler {
    border-color: $navbar-default-toggle-border-color;
    &:hover,
    &:focus {
      background-color: $navbar-default-toggle-hover-bg;
    }
    .togo-icon-hamburger {
      color: $lite-navbar-default-link-active-color;
    }
    .icon-bar {
      background-color: $navbar-default-toggle-icon-bar-bg;
    }
  }

  // Dropdown menu items
  .navbar-nav {

    // Remove background color from show dropdown
    > .show > a {

      &.dropdown-toggler,
      &.dropdown-toggler:hover,
      &.dropdown-toggler:focus {
        background-color: $navbar-default-link-hover-bg;
      }

      &,
      &:hover,
      &:focus {
        background-color: $lite-navbar-default-link-active-bg;
        color: $lite-navbar-default-link-active-color;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      .show .icon-label-lite,
      .show .user-name {
        color: $lite-navbar-default-link-active-color !important;
      }

      // Dropdowns get custom display when collapsed
      .show .dropdown-menu {
        > li > a, > li > span {
          color: $lite-navbar-default-link-active-color;
          &:hover,
          &:focus {
            color: $lite-navbar-default-link-hover-color;
            background-color: $navbar-default-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: $lite-navbar-default-link-active-color;
            background-color: $lite-navbar-default-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-default-link-disabled-color;
            background-color: $navbar-default-link-disabled-bg;
          }
        }
      }
    }
  }

  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: $lite-navbar-default-link-color;
    &:hover {
      color: $lite-navbar-default-link-hover-color;
    }
  }
}
