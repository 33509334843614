@import '../../styles/variables';

.footer-container {
  letter-spacing: normal;
  color: $grey-street;
  background-color: $black-pearl;
  position: absolute;
  bottom: 0;
  width: 100%;

  .openvoice-legal-text {
    font-size: 11px;
    line-height: 16px;
    padding-top: 0;
    padding-bottom: 15px;
  }

  .links-copyright {
    font-size: $font-sm;

    a {
      color: $grey-street;
    }
  }
}

