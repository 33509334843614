@import 'navbar_variables';

//
// Colors
// --------------------------------------------------

//
// Product Colors
// .togo-color-PRODUCT to be used when the background color is light, .togo-color-PRODUCT-light to be used when the background color is dark
// --------------------------------------------------

.togo-color-g2m {
  color: $g2m-color;
}

.togo-color-g2m-light {
  color: $g2m-color-light;
}

.togo-color-g2t {
  color: $g2t-color;
}

.togo-color-g2t-light {
  color: $g2t-color-light;
}

.togo-color-g2w {
  color: $g2w-color;
}

.togo-color-g2w-light {
  color: $g2w-color-light;
}

.togo-color-openvoice {
  color: $openvoice-color;
}

.togo-color-openvoice-light {
  color: $openvoice-color-light;
}

.togo-color-g2a {
  color: $g2a-color;
}

.togo-color-g2a-light {
  color: $g2a-color-light;
}

//
// Product Background Colors
// .togo-bg-color-PRODUCT to be used when the background color is light, .togo-bg-color-PRODUCT-light to be used when the background color is dark
// --------------------------------------------------

.togo-bg-color-g2m {
  background-color: $g2m-color;
}

.togo-bg-color-g2m-light {
  background-color: $g2m-color-light;
}

.togo-bg-color-g2t {
  background-color: $g2t-color;
}

.togo-bg-color-g2t-light {
  background-color: $g2t-color-light;
}

.togo-bg-color-g2w {
  background-color: $g2w-color;
}

.togo-bg-color-g2w-light {
  background-color: $g2w-color-light;
}

.togo-bg-color-openvoice {
  background-color: $openvoice-color;
}

.togo-bg-color-openvoice-light {
  background-color: $openvoice-color-light;
}

.togo-bg-color-g2a {
  background-color: $g2a-color;
}

.togo-bg-color-g2a-light {
  background-color: $g2a-color-light;
}
