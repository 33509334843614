@import '../../styles/variables';

.mainContainer {
  padding-top: 0px;
  padding-bottom: 100px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: $screen-sm-max) {
  .mainContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
