.feature-detail {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
    width: 75%;
}

.feature-detail-content {
    display: flex;
    gap: 1rem
}

.feature-detail-icon {
    font-size: 24px;
    color: #2C72DE;
}

.feature-detail-content-body {
    font-size: 14px;
}