@import 'mixins';

// Animation for when a page is loaded or  when we load a new route
.page-appear, .page-enter {
  @include opacity-visible-start-animation();
}

.page-appear.page-appear-active, .page-enter.page-enter-active {
  @include opacity-visible-end-animation(all .25s linear);
}

// Subscriptions overview - details view animation
.details-view-enter {
  @include slide-in-opacity-start-animation();
}

.details-view-enter.details-view-enter-active {
  @include slide-in-opacity-end-animation(200px, all .25s linear);
}

.details-view-exit {
  @include slide-out-opacity-start-animation(200px);
}

.details-view-exit.details-view-exit-active {
  @include slide-out-opacity-end-animation(all .15s ease-out);
}

// Subscriptions overview - details view short animation
.details-view-short-enter {
  @include opacity-visible-start-animation();
}
.details-view-short-enter.details-view-short-enter-active {
  @include opacity-visible-end-animation( all .25s ease-in);
}
.details-view-short-exit {
  @include opacity-hidden-start-animation();
}
.details-view-short-exit.details-view-short-exit-active {
  @include opacity-hidden-end-animation(all .15s ease-in);
}

// Subscription config - selected row animation
.sub-config-selected-row-enter {
  @include slide-in-opacity-start-animation();
}

.sub-config-selected-row-enter.sub-config-selected-row-enter-active {
  @include slide-in-opacity-end-animation(200px, all .5s linear);
}

.sub-config-selected-row-exit {
  @include slide-out-opacity-start-animation(200px);
}

.sub-config-selected-row-exit.sub-config-selected-row-exit-active {
  @include slide-out-opacity-end-animation(all .5s ease-out);
}

// Subscription config - section animation
// @TODO - minor UI bug, figure out what causes the small bounce on the open animation in the quantity section
.sub-config-section-enter {
  @include slide-in-opacity-start-animation();
}

.sub-config-section-enter.sub-config-section-enter-active {
  @include slide-in-opacity-end-animation(500px, all .5s linear);
}

.sub-config-section-exit {
  @include slide-out-opacity-start-animation(500px);
}

.sub-config-section-exit.sub-config-section-exit-active {
  @include slide-out-opacity-end-animation(all .5s ease-out);
}

// For any CSSTransition elements that we don't want to have `unmountOnExit` set
.sub-config-section-exit-done {
  display: none;
}

// Subscriptions Overview - Product Families Dropdown
.product-families-dropdown-enter {
  @include slide-in-opacity-start-animation();
}

.product-families-dropdown-enter.product-families-dropdown-enter-active {
  @include slide-in-opacity-end-animation(735px, all .25s linear);
}

.product-families-dropdown-exit {
  @include slide-out-opacity-start-animation(735px);
}

.product-families-dropdown-exit.product-families-dropdown-exit-active {
  @include slide-out-opacity-end-animation(all .25s ease-out);
}
