@import '../../styles/variables';

.alert-container {
  text-align: center;
}

@media (min-width: $screen-xs-max) {
  .content {
    padding: 0 2.5%;
  }
  .alert-container {
    text-align: left;
  }
  .alert-heading {
    padding-top: 3px;
    font-size: $font-md;

    i {
      vertical-align: text-bottom;
      margin-bottom: 2px;
    }
  }

  .alert-buttons {
    margin-bottom: -10px;
  }
}

