// https://github.com/aaronshaf/react-toggle, copied over styles @ v2.1.1 and modified them

@import 'variables';

.react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

// The oval shaped track that the circle moves in
.react-toggle-track {
  width: 53px;
  height: 30px;
  padding: 0;
  border-radius: 30px;
  border: 1px solid $silver-soul;
  background-color: $satin-doll;
  box-shadow: 1px 2px 3px 0 rgba($white, 0.15) inset;
  transition: all 0.2s ease;
}

// React toggle thumb is the circle that moves from side to side in the toggle
.react-toggle-thumb {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $dodger-blue;
  box-shadow: -2px 0 1px 1px rgba($white,.15);
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 26px;
  box-shadow: 2px 0 1px 1px rgba($white,.15);
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.toggle-label-left {
  position: relative;
  right: 12px;
  font-size: 13px;
  color: $dark-fantasy;
}

.toggle-label-right {
  position: relative;
  left: 12px;
  font-size: 13px;
  color: $dark-fantasy;
}
