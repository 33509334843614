@import '../../styles/variables';

.unauthorized-view {
  padding-top: 80px;
  text-align: center;

  h5 {
    line-height: 1.5;
  }

  i {
    color: $dodger-blue;
    font-size: 6.8em;
  }
}
