.alert-try-switch-body {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.alert-try-switch-message {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}

.alert-try-switch-info {
    margin-top: 2px;
    font-size: 18px;
}

.alert-try-switch-learn-more {
    background-color: #2C72DE;
    color: white !important;
    margin-left: 1rem;
}

.alert-try-switch-modal {
    @media (min-width: 600px) {
        width: 600px;
        max-width: 100%;
    }
}

.alert-try-switch-modal-close {
    font-size: 24px;
    align-self: flex-end;
    cursor: pointer;
}

.alert-try-switch-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.alert-try-switch-modal-title {
    margin-bottom: 0.5rem;
}

.alert-try-switch-modal-body-message {
    font-size: 16px;
    font-weight: 400;
}

.alert-try-switch-modal-divider {
    width: 100%;
}

.alert-try-switch-gta {
    background-color: #2C72DE;
    color: white !important;
}

.alert-try-switch-preview {
    background-color: white;
    color: #006AFF !important;
}

.alert-try-switch-preview-link {
    width: 100%;
    margin-top: 0.5rem;
}
    