@import '../../styles/variables';

.not-found-view {
  text-align: center;
  padding-top: 80px;

  h5, h2 {
    font-weight: 300;
  }

  h2 {
    font-size: 30px;
  }
}
