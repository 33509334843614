@import 'navbar_variables';
@import '_mixins';

// The dropdown menu (ul)
.dropdown-menu {
  font-size: $font-size-base;

  // Dividers (basically an hr) within the dropdown
  .divider {
    @include nav-divider($dropdown-divider-bg);
    opacity: 0.3;
  }

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 5px 15px 5px 30px;
    clear: both;
    font-weight: normal;
    line-height: 1;
    color: $dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines

    &:hover,
    &:focus {
      text-decoration: none;
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }

    &.selected {
      padding: 5px 15px 5px 10px;
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
      font-weight: $dropdown-selected-font-weight;

      > i > ul > li > a > i {
        margin-right: 5px;
        color: $brand-secondary;
      }
    }
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: $dropdown-link-active-bg;
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu > .disabled > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-disabled-color;
  }
}
// Nuke hover/focus effects
.dropdown-menu > .disabled > a {
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    @include reset-filter();
    cursor: not-allowed;
  }
}

// show state for the dropdown
.show {
  // Show the menu
  > .dropdown-menu {
    display: block;
    border: 1px solid $dropdown-border;
    border-top: none;
  }

  .btn-dropdown {
    @include border-bottom-radius(0);
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: $font-size-small;
  line-height: $line-height-base;
  color: $dropdown-header-color;
}

.dropdown ul.dropdown-menu.nav-dropdown-menu {
  padding: 0 25px;
  background-color: $navbar-default-bg;
  border: none;

  li > a {
    // Size, position, other
    display: inline-block;
    margin: 18px 0;
    padding: 0;
    width: $nav-dropdown-width;
    max-width: $nav-dropdown-max-width;
    background-color: $navbar-default-bg;
    cursor: pointer;

    // Font
    font-size: 15px;
    font-weight: normal;
    color: $creme-gray;

    i {
      margin-right: 5px;
    }

    
    &:hover,
    &:focus,
    &.selected {
      color: $nav-white;
      background-color: $navbar-default-bg;
    }
  }

  .G2M {
    i {
      background: url('~static/images/logos/g2m.svg');
    }
    &:hover {
      i {
        background: url('~static/images/logos/g2m-invert.svg');
      }
    }
  }
  
  .G2W {
    i {
      background: url('~static/images/logos/g2w.svg');
    }
    &:hover {
      i {
        background: url('~static/images/logos/g2w-invert.svg');
      }
    }
  }
  
  .G2T {
    i {
      background: url('~static/images/logos/g2t.svg');
    }
    &:hover {
      i {
        background: url('~static/images/logos/g2t-invert.svg');
      }
    }
  }
  
}

// Animate the dropdown menu to slide in and out (we do this using the max-height property)
.dropdown-menu {
  -webkit-transition: max-height .2s cubic-bezier(.38,.74,.25,.96);
  -moz-transition: max-height .2s cubic-bezier(.38,.74,.25,.96);
  -o-transition: max-height .2s cubic-bezier(.38,.74,.25,.96);
  transition: max-height .2s cubic-bezier(.38,.74,.25,.96); // This is the transition used when the dropdown menu is closing
  max-height: 0;
  display: block;
  overflow: hidden;
}

.show .dropdown-menu {
  -webkit-transition: max-height .2s cubic-bezier(1,.01,.96,.62);
  -moz-transition: max-height .2s cubic-bezier(1,.01,.96,.62);
  -o-transition: max-height .2s cubic-bezier(1,.01,.96,.62);
  transition: max-height .2s cubic-bezier(1,.01,.96,.62); // This is the transition used when the dropdown menu is showing
  max-height: 1000px;
}

.dropdown-menu.no-transition, .show .dropdown-menu.no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

//== Lite theme classes

.navbar-lite {
  // The dropdown menu (ul)
  .dropdown-menu {

    // Dividers (basically an hr) within the dropdown
    .divider {
      height: 1px;
      margin: (($line-height-computed / 2) - 1) 0;
      overflow: hidden;
      border-bottom: 1px solid $lite-nav-menu-separator-color !important;
      opacity: 1;
      background-color: transparent;
    }

    // Links within the dropdown menu
    > li > a {

      > i {
        display: inline-block;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        text-align: center;
        padding-top: 3px;
        position: relative;
        top: -4px;
        font-size: 18px;
      }

      > i.togo-dropdown-link-icon {
        color: $lite-navbar-default-link-icon-color;
      }

      &.selected {
        padding: inherit;
        color: inherit;
        background-color: transparent;
        font-family: $font-family-strong;

        > i {
          margin-right: 0;
          background-color: $light-background;
        }

        > i.togo-dropdown-link-icon {
          color: $lite-navbar-default-link-active-color;
        }
      }
    }
  }

  // Active state
  .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      background-color: $lite-navbar-default-bg;
    }
  }


  .dropdown ul.dropdown-menu.nav-dropdown-menu {
    background-color: $lite-navbar-default-bg;
    border: none;
    font-weight: normal;
    overflow: hidden;
    padding: 0;

    li {
      padding-left: 25px;
      padding-right: 25px;
    }

    li:not(.dropdown-menu-user-info) {
      &:hover,
      &:focus,
      &:hover > a,
      &:focus > a,
      &:hover > a > i,
      &:focus > a > i {
        color: $white;
        background-color: $lite-navbar-default-link-hover-color
      }
    }

    li > a {
      // Size, position, other
      display: inline-block;
      margin: 2px 0;
      padding: 8px 0 0;
      width: $nav-dropdown-width;
      max-width: $nav-dropdown-max-width;
      background-color: transparent;
      cursor: pointer;

      // Font
      font-size: 14px;
      color: $lite-navbar-default-link-active-color;

      i {
        margin-right: 12px;
        font-size: 18px;
        vertical-align: text-top;
      }

      &.selected {
        font-weight: $font-family-strong;
      }
    }

    &:before {
      position: absolute;
      top: -15px;
      right: 30px;
      content: '';
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 16px solid $nav-white;
      z-index: 2;
    }
    &:after {
      position: absolute;
      top: -17px;
      right: 28px;
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 17px solid rgba($nav-white, 0.03);
      z-index: 1;
    }
  }

  // Animate the dropdown menu to slide in and out (we do this using the max-height property)
  .dropdown {

    .dropdown-menu {
      max-height: 0;
      display: block;
      overflow: hidden;
    }

    &.show .dropdown-menu {
      -webkit-transition: max-height .2s cubic-bezier(1, .01, .96, .62);
      -moz-transition: max-height .2s cubic-bezier(1, .01, .96, .62);
      -o-transition: max-height .2s cubic-bezier(1, .01, .96, .62);
      transition: max-height .2s cubic-bezier(1, .01, .96, .62); // This is the transition used when the dropdown menu is showing
      max-height: 1000px;
      overflow: visible;
    }

    .dropdown-menu.no-transition, &.show .dropdown-menu.no-transition {
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      transition: none;
    }

    // Since the dropdown-menu class is displayed as a block, we can't have any padding on the top or bottom of the menu
    // Otherwise we would see the dropdown menu even when it's not show
    // The workaround is to add margins to the first and last elements in the list (No IE 8 support for :first-child and :last-child)
    .dropdown-menu.nav-dropdown-menu {
      li:first-child {
        margin-top: 10px;
      }

      li:last-child {
        margin-bottom: 10px;
      }
    }
  }
}
