@import '../../../styles/variables';

.cart-button > span {
  padding: 0;
}

.cart-icon-badge {
  top: 14px;
  left: 53px;
  background-color: $java-green;
  font-family: $font-lato;

  @media(max-width: 768px) {
    top: auto;
  }
}
