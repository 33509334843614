@import '../../styles/variables';

.badge-circle {
  display: inline-block;
  border-radius: 50%;
  min-width: 18px;
  min-height: 18px;
  color: $white;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  position: absolute;

  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    letter-spacing: normal;
    font-weight: bold;
  }

  &:before {
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    content: '';
  }
}
