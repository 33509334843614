.cardinalOverlay-mask {
	position: fixed;
	z-index: 999998;
	top: 0;
	left: 0;
	opacity: 0;
	display: none;
	width: 100%;
	height: 100%;
	transition: opacity 1ms ease;
	background: rgba(0, 0, 0, .6)
}

.cardinalOverlay-content {
	position: fixed;
	z-index: 999999;
	top: 50%;
	left: 50%;
	opacity: 0;
	padding: 24px 20px;
	transform: translate(-50%, -50%);
	border-radius: 2px;
	background: #fff
}

@media screen and (max-width:425px) {
	.cardinalOverlay-content {
		max-width: 410px;
		padding: .5em
	}
}

@media screen and (max-width:375px) {
	.cardinalOverlay-content {
		max-width: 365px;
		padding: .5em
	}
}

@media screen and (max-height:375px) and (orientation:landscape) {
	.cardinalOverlay-content {
		max-height: 365px;
		padding-top: .5em;
		padding-bottom: .5em
	}
}

@media screen and (max-width:320px) {
	.cardinalOverlay-content {
		max-width: 310px;
		padding: .5em
	}
}

@media screen and (max-height:320px) and (orientation:landscape) {
	.cardinalOverlay-content {
		max-height: 310px;
		padding-top: .5em;
		padding-bottom: .5em
	}
}

.legacy {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	filter: alpha(opacity=50);
	background-color: #000
}

.cardinalOverlay-closeButton {
	font-family: Helvetica, Arial, sans-serif;
	font-size: 25px;
	line-height: 12px;
	position: absolute;
	top: 2px;
	right: -3px;
	cursor: pointer;
	color: #999;
	border: 0;
	outline: none;
	background: none
}

.cardinalOverlay-closeButton:hover {
	color: #000
}

.cardinalOverlay-content.cardinalOverlay-open,
.cardinalOverlay-mask.cardinalOverlay-open {
	display: block;
	opacity: 1;
	transition: opacity .5s ease
}

.shadow-effect {
	-ms-box-shadow: 0 6px 22px -3px #000;
	-o-box-shadow: 0 6px 22px -3px #000;
	box-shadow: 0 6px 22px -3px #000
}

#Cardinal-MerchantContent {
	width: 100%;
	float: left
}

#Cardinal-ModalContent {
	height: 100%;
	width: 100%
}

@media screen and (max-width:425px) {
	#Cardinal-ModalContent {
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll;
		max-width: 390px
	}
}

@media screen and (max-height:425px) and (orientation:landscape) {
	#Cardinal-ModalContent {
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll;
		max-height: 360px
	}
}

@media screen and (max-width:375px) {
	#Cardinal-ModalContent {
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll;
		max-width: 350px
	}
}

@media screen and (max-height:375px) and (orientation:landscape) {
	#Cardinal-ModalContent {
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll;
		max-height: 350px
	}
}

@media screen and (max-width:320px) {
	#Cardinal-ModalContent {
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll;
		max-width: 300px
	}
}

@media screen and (max-height:320px) and (orientation:landscape) {
	#Cardinal-ModalContent {
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll;
		max-height: 295px
	}
}

@media screen and (min-width:768px) and (max-width:1024px) {
	#Cardinal-ModalContent {
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll
	}
}

#Cardinal-ModalContent.size-01,
#Cardinal-ModalContent.size-02 {
	height: 400px
}

#Cardinal-ModalContent.size-03 {
	height: 600px
}

#Cardinal-ModalContent.size-04 {
	height: 400px
}

#Cardinal-CCA-IFrame {
	display: block;
	margin: 0 auto
}

@media screen and (max-width:425px) {
	#Cardinal-CCA-IFrame {
		max-width: 390px
	}
}

@media screen and (max-height:425px) and (orientation:landscape) {
	#Cardinal-CCA-IFrame {
		max-height: 360px
	}
}

@media screen and (max-width:375px) {
	#Cardinal-CCA-IFrame {
		max-width: 350px
	}
}

@media screen and (max-height:375px) and (orientation:landscape) {
	#Cardinal-CCA-IFrame {
		max-height: 350px
	}
}

@media screen and (max-width:320px) {
	#Cardinal-CCA-IFrame {
		max-width: 300px
	}
}

@media screen and (max-height:320px) and (orientation:landscape) {
	#Cardinal-CCA-IFrame {
		max-height: 295px
	}
}

.cardinal-hide {
	display: none
}

.cardinal-img-responsive {
	max-width: 100%;
	height: auto;
	display: block
}

.cardinal-center-block {
	display: block;
	margin: 0 auto
}

.cardinal-text-center {
	text-align: center
}