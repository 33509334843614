@import 'variables';

// ----- Animation mixins -----
// Make visible / hidden animation using opacity
@mixin opacity-visible-start-animation() {
  overflow: hidden;
  opacity: 0.01;
}

@mixin opacity-visible-end-animation($transition) {
  opacity: 1;
  transition: $transition;
}

@mixin opacity-hidden-start-animation() {
  overflow: hidden;
  opacity: 1;
}

@mixin opacity-hidden-end-animation($transition) {
  opacity: 0.01;
  transition: $transition;
}

// Make slide in / out animation with opacity effect (transition opacity and max height)
@mixin slide-in-opacity-start-animation() {
  overflow: hidden;
  opacity: 0.01;
  max-height: 0;
}

@mixin slide-in-opacity-end-animation($max-height, $transition) {
  opacity: 1;
  max-height: $max-height;
  transition: $transition;
}

@mixin slide-out-opacity-start-animation($max-height) {
  overflow: hidden;
  opacity: 1;
  max-height: $max-height;
}

@mixin slide-out-opacity-end-animation($transition) {
  opacity: 0.01;
  max-height: 0;
  transition: $transition;
}

@mixin right-margin-only($margin) {
  margin: 0 $margin 0 0;
}

// navbar-lite

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
   border-bottom-left-radius: $radius;
}

@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
   border-top-left-radius: $radius;
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
          box-shadow: $shadow;
}

@mixin nav-divider($color: $satin-doll) {
  height: 1px;
  margin: (($line-height-computed / 2) - 1) 0;
  overflow: hidden;
  background-color: $color;
}

@mixin reset-filter() {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin navbar-vertical-align-lite($element-height) {
  margin-top: (($lite-navbar-height - $element-height) / 2);
  margin-bottom: (($lite-navbar-height - $element-height) / 2);
}

// Chameleon Button:
@mixin gradient-directional($start-color, $end-color, $deg: 45deg) {
  background-image: linear-gradient($deg, $start-color, $end-color);
  background-repeat: repeat-x;
}

// Slideout & Modals
@mixin chameleon-slide-out-to-right() {
  transform: translate3d(100vw, 0, 0);
}

@mixin chameleon-slide-in() {
  transform: translate3d(0, 0, 0);
}

@mixin chameleon-transition-ease-out($duration) {
  transition: transform #{$duration} #{$easeOut};
}
