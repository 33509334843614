@import '../../styles/variables';

.radio-label {
  position: relative;
  top: 20px;
  left: 25px;
  font-weight: $font-lato-bold-weight;
  font-size: 16px;
}

.spacer {
  height: 5px;
}
