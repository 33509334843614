// Chameleon Project Colors
$aqua-skies: #28D7E2;
$blue-dk: #0092E2;
$blue-lt: #14B2F0;
$blue: #007bff;
$cyan: #17a2b8;
$dark-fantasy: #444444;
$dark: #444444;
$distant-graphite: #A4A6B3;
$electric-blue: #00C2FF;
$gray-dark: #343a40;
$gray: #6c757d;
$green-light: #18E4B6;
$green: #28a745;
$grey-street: #8B8D99;
$indigo: #6610f2;
$info: #46A9F6;
$light: #dfe6ee;
$metallic-cloud: #C0BEC0;
$mr-blue-sky-dk: #0F9FEE;
$mr-blue-sky-lt: #2AC8F6;
$orange: #fd7e14;
$pink: #e83e8c;
$pretty-in-pink: #FE0577;
$primary: #00C2FF;
$purple-rain: #8A5BE4;
$purple: #6f42c1;
$radical-red: #F53D5A;
$red: #dc3545;
$satin-doll: #E0E3E7;
$secondary: #FE0577;
$silver-soul: #D0D2DD;
$success: #00B984;
$teal: #20c997;
$touch-of-grey: #F1F2F4;
$warm-gray: #9b9b9b;
$warning: #FFB43A;
$white: #ffffff;
$yellow: #ffc107;
$black: #000000;
$cadet-blue: #ABBCC7;
$cyan-blue-dk: #2C72DE;
$gunmetal-gray: #2A3238;
$gunmetal-gray-trans: rgba(42, 50, 56, 0.7);
$button-hover-bg: rgba(88, 121, 143, 0.1);
$button-hover-border: rgba(88, 121, 143, 0.5);
$button-active-bg: rgba(88, 121, 143, 0.5);


// Billing Portal Custom Colors
$black-pearl: #081B31;
$sunset-orange: #FA5142;
$casablanca-orange: #FDB92B;
$lima-green: #78BE23;
$laurel-green: #049F16;
$jade-green: #01B17B;
$java-green: #16D8A0;
$malibu-blue: #65C5FF;
$dodger-blue: #479FFE;
$denim-blue: #1678E3;
$dark-purple: #342E41;
$gray-light: #CED4Da;

// Billing Portal Custom Button Gradients
$button-linear-gradient: linear-gradient(255deg, $gunmetal-gray, $gunmetal-gray);
$button-hover-overlay-gradient: linear-gradient(180deg,rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
$button-focus-box-shadow: 0 0 1px 1px $denim-blue;

// http://getbootstrap.com/css/#grid
$screen-xxs-max: 320px; // older mobile phones
$screen-xs-max: 767px; // mobile phone
$screen-sm-max: 991px; // tablet
$screen-md-max: 1199px; // laptop

$font-xxl: 42px;
$font-xl: 32px;
$font-lg: 24px;
$font-md: 18px;
$font-sm: 14px;
$font-xs: 11px;
$font-lato: Lato;
$font-lato-bold-weight: 700;
$font-lato-semibold-weight: 600;
$font-lato-normal-weight: 400;
$font-lato-light-weight: 300;

// Animation
$easeOut: cubic-bezier(0.25, 0.46, 0.45, 0.94);
